<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showfact"
      max-width="800px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            Montant :
            {{
              numberWithSpace(
                fact ? (fact.mont_ttc ? fact.mont_ttc.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
          <v-spacer></v-spacer>
          <span>
            Lettrages :
            {{
              numberWithSpace(
                fact ? (fact.ttc_let ? fact.ttc_let.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <listitems
            :list="fact_list"
            :headers="factheaders"
            :master="false"
            :add="add"
            :del="del"
            :Update="false"
            :showedit="false"
            @open="add_fact"
            @delete="del_fact"
          >
          </listitems>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click.stop="closeExp">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import listitems from "./ListItems.vue";
export default {
  components: {
    listitems,
  },
  props: {
    fact: Object,
    fact_list: Array,
    showfact: Boolean,
    add: { type: Boolean, default: false },
    del: { type: Boolean, default: false },
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",

      factheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Facture No",
          value: "nofact",
          selected: true,
        },
        {
          text: "Reference",
          value: "ref_fact",
          selected: true,
        },
        {
          text: "Date ",
          value: "fact_date",
          slot: "date",
          selected: true,
        },

        {
          text: "TTC",
          value: "mont_ttc",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "Montant Lettrage",
          value: "montant",
          selected: true,
          slot: "cur",
          align: "end",
        },
      ],
    };
  },
  methods: {
    add_fact() {
      this.$emit("add");
    },
    del_fact(item) {
      this.$emit("del", item);
    },
    closeExp() {
      this.$emit("close");
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
